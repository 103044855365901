<template>
    <el-table
        border
        :data="tableData"
        tooltip-effect="dark"
        empty-text="请上传文件"
        header-cell-class-name="meterHeader"
        >
        <slot name="tableColumn">
            <el-table-column prop="fileName" label="文件名称" align="center"  />
        </slot>
        <el-table-column align="center" width="100">
            <template slot="header" slot-scope="scope">
                <AddRow @add-row="addEmptyRowHandler"></AddRow>
                <!-- <Upload :accept="accept" :fields="fields" :multiple="true" @upload="upload"></Upload> -->
            </template>
            <template slot-scope="scope">
                <slot name="operation" :scope="scope">
                    <i class="iconfont iconerror" @click="del(scope.$index)"></i>
                </slot>
            </template>

        </el-table-column>
    </el-table>
</template>

<script>

export default {
    components: {
        // Upload: () => import("./Upload.vue"),
        AddRow: () => import("../AddRow.vue")
    },
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        value: {
            type: Array,
            default: function (){
                return []
            }
        },
        accept:{
            type:String,
            default:'image/*',
        },
        fields:{
            type: Object,
            default:function(){
                return {
                    fileName:'fileName',
                    fileAddress:'fileAddress'
                }
            }
        },
    },
    data() {
        return {
            tableData:[],
        }
    },
    watch: {
        value:{
            immediate:true,//初始化立即执行
            handler: function (newVal){
                this.tableData = newVal||[];
            }
        },
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    mounted() {

    },
    methods: {
        // upload(r){       
        //     r.some(res=>{
        //         this.tableData.push(res);
        //     });
        //     this.$emit('change', this.tableData);
        // },
        addEmptyRowHandler() {
            event.preventDefault();
            this.tableData.push({ });
            this.$emit('change', this.tableData);
        },
        del(){

        }
    }
};
</script>

<style lang="scss" scoped>

</style>
